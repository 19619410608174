import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Container } from '@mui/material';

export default function Header() {
    const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
    
    return (
        <AppBar>
            <Toolbar style={{ alignItems: "left", justifyContent: "left" }}>
                <Container sx={{ display: 'flex', alignItems: 'space-between' }}>
                    <NavLink
                        to="/">
                        Persona
                    </NavLink>
                    <NavLink
                        to="/features">
                        Features
                    </NavLink>
                    <NavLink
                        to="/pricing">
                        Pricing
                    </NavLink>
                    <NavLink
                        to="/enterprise">
                        Enterprise
                    </NavLink>
                    <NavLink
                        to="/support">
                        Support
                    </NavLink>
                    {isAuthenticated ? (
                        <Container sx={{ display: 'flex', alignItems: 'space-between' }}>
                            <NavLink
                                to="/profile">
                                <Avatar alt={user.name} src={user.image} />
                            </NavLink>
                            <Button onClick={() => logout()}>
                                Logout
                            </Button>
                        </Container>
                    )
                        :
                        <Button onClick={() => loginWithRedirect()}>
                            Login
                        </Button>
                    }
                </Container>
            </Toolbar>
        </AppBar>
    );
};