import Header from './Header';
import Landing from './Landing';
import Pricing from './Pricing';
import Footer from './Footer';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Profile from './Profile';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
};
